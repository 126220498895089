import { GIWGLookingForVariables, OptimizelyState } from "../types";
import {
  FeatureFlagVariation,
  FeatureFlags,
} from "../utils/optimizely/optimizelyFeatureFlags";
import useLocalStorage from "./useLocalStorage";
import useOptimizelyDecision from "./useOptimizelyDecision";

export function useOptimizely(): OptimizelyState {
  // /* Optimizely GIWG experiment demo */
  const giwgInformationLookingForDecision = useOptimizelyDecision(
    FeatureFlags.GIWG_ADDITIONAL_QUESTION
  ).decision;

  const {
    enabled: giwgExperimentEnabled,
    variables,
    variationKey: variationKeyGIWG,
  } = giwgInformationLookingForDecision;

  const giwgLookingForVariables = variables
    ? (variables.dropdown_value as unknown as GIWGLookingForVariables)
    : undefined;

  const enabledExperimentGIWGVariation =
    giwgExperimentEnabled && variationKeyGIWG === FeatureFlagVariation.ON;

  /* Optimizely GIWG experiment demo end */

  const optimizelyState = {
    giwgLookingForVariables,
    enabledExperimentGIWGVariation,
  };

  useLocalStorage(
    "enabledExperimentGIWGVariation",
    enabledExperimentGIWGVariation ? "true" : "false"
  );

  return optimizelyState;
}
