import {
  Box,
  Collapse,
  IconFa,
  Select,
  Text,
  TextField,
} from "@cruk/cruk-react-components";
import { ChangeEvent } from "react";
import { Event } from "@cruk/activity-management-schema";
import { faCheckCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import EventLookupWrapper from "../../components/EventLookupWrapper";
import GenericEventCancelIconWrapper from "../../components/GenericEventCancelIconWrapper";
import GenericEventSuccessIconWrapper from "../../components/GenericEventSuccessIconWrapper";
import IconBoxWrapper from "../../components/IconBoxWrapper";
import List from "../../components/List";
import ListItem from "../../components/ListItem";
import ListWrapper from "../../components/ListWrapper";
import SpecificSearchCancelIconWrapper from "../../components/SpecificSearchCancelIconWrapper";
import SpecificSearchSuccessIconWrapper from "../../components/SpecificSearchSuccessIconWrapper";
import { RegistrationType } from "../../contexts/RegistrationContext";
import { FormFieldProps } from "../../types";

const EventLookup = ({
  field,
  values,
  errors,
  touched,
  handleChange,
  setValues,
  handleBlur,
  setEventsAPI,
  setEventRegistered,
  setEvents,
  events,
  journey,
  journeyHelper,
}: FormFieldProps) => {
  return (
    <EventLookupWrapper>
      <Box marginBottom="s" key={field}>
        <Box marginBottom="auto">
          <TextField
            label="Event search"
            hintText="For example, Brighton Marathon"
            placeholder=""
            type="text"
            aria-label="This is a search field type in this box to find the event you have entered"
            id="eventLookup"
            name="eventName"
            disabled={values.isGenericEvent}
            value={!values.isGenericEvent ? values.eventName : ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChange(event);
              setValues({
                ...values,
                eventCode: "",
                eventName: event.target.value,
              } as RegistrationType);
              setEventsAPI("EventList", event.target.value);
            }}
            onBlur={handleBlur}
            errorMessage={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              touched.eventName && !values.isGenericEvent
                ? errors.eventName
                : undefined
            }
          />
        </Box>
        {values.eventCode && !values.isGenericEvent ? (
          <IconBoxWrapper>
            <SpecificSearchSuccessIconWrapper>
              <IconFa
                faIcon={faCheckCircle}
                color="green"
                aria-label="CheckCircle"
                size="25px"
              />
            </SpecificSearchSuccessIconWrapper>
            <SpecificSearchCancelIconWrapper
              onClick={() => {
                setValues({
                  ...(values as RegistrationType),
                  eventCode: "",
                  eventName: "",
                  tshirtOrVest: "",
                  maleFemaleStyles: "",
                  haveEnteredEvent: false,
                  eventTypes: [],
                  eventStart: "",
                });
                if (journeyHelper.isSportsFormPromoterJourney(journey)) {
                  setEventRegistered(undefined);
                }
                setEvents([]);
              }}
            >
              <IconFa
                faIcon={faClose}
                color="gray"
                aria-label="CloseIcon"
                size="25px"
              />
            </SpecificSearchCancelIconWrapper>
          </IconBoxWrapper>
        ) : (
          <></>
        )}
        {events &&
          events.length > 0 &&
          values.eventName &&
          !values.isGenericEvent && (
            <ListWrapper>
              <List key={`${field}Result`}>
                {events.map((event: Event, index) => (
                  <ListItem
                    key={event.event_code}
                    id={`eventOption-${index}`}
                    aria-label={event.title}
                    onClick={() => {
                      setValues({
                        ...(values as RegistrationType),
                        eventName: event.title,
                        eventCode: event.event_code,
                        eventTypes: event.event_types,
                        eventStart: event.date_start,
                      });
                      if (journeyHelper.isSportsFormPromoterJourney(journey)) {
                        setEventRegistered(event);
                      }
                      setEvents([]);
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <Text as="span" textSize="s">
                      {event.title}
                    </Text>
                  </ListItem>
                ))}
              </List>
            </ListWrapper>
          )}
      </Box>
      {((!values.eventCode && !values.isGenericEvent) ||
        !!values.isGenericEvent) && (
        <Collapse
          headerTitleText={
            !values.isGenericEvent
              ? "Can't find your event?"
              : "Choose a specific event"
          }
          startOpen={values.isGenericEvent}
          id="cant-find-your-event"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onOpenChange={async (isOpen) => {
            if (isOpen) {
              setValues({
                ...(values as RegistrationType),
                eventName: "",
                eventCode: "",
                tshirtOrVest: "",
                maleFemaleStyles: "",
                haveEnteredEvent: false,
                isGenericEvent: true,
                eventTypes: [],
                eventStart: "",
              });
              await setEventsAPI("EventList", "Generic Events");
            } else {
              setValues({
                ...(values as RegistrationType),
                eventName: "",
                eventCode: "",
                tshirtOrVest: "",
                maleFemaleStyles: "",
                eventTypes: [],
                eventStart: "",
                haveEnteredEvent: false,
                isGenericEvent: false,
              });
              if (journeyHelper.isSportsFormPromoterJourney(journey)) {
                setEventRegistered(undefined);
              }
              setEvents([]);
            }
          }}
        >
          <Box marginBottom="s" key="GenericEvent">
            <Select
              label="Tell us what kind of event you're fundraising for using the options below"
              name="eventName"
              value={values.eventName}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                const eventFound = events.find(
                  (ev: Event) => ev.title === event.target.value
                );
                setValues({
                  ...(values as RegistrationType),
                  eventCode: eventFound && eventFound.event_code,
                  eventTypes: eventFound && eventFound.event_types,
                  eventStart: eventFound && eventFound.date_start,
                });
                if (journeyHelper.isSportsFormPromoterJourney(journey)) {
                  setEventRegistered(eventFound);
                }
                handleChange(event);
              }}
              errorMessage={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                touched.eventName ? errors.eventName : undefined
              }
            >
              <option disabled value="">
                Choose a type of event
              </option>
              {events &&
                events.length > 0 &&
                events.map((event: Event) => (
                  <option key={event.event_code} value={event.title}>
                    {event.title}
                  </option>
                ))}
            </Select>
          </Box>
          {values.eventCode && values.isGenericEvent ? (
            <IconBoxWrapper>
              <GenericEventSuccessIconWrapper>
                <IconFa
                  faIcon={faCheckCircle}
                  color="green"
                  size="25px"
                  aria-label="CheckCircle"
                />
              </GenericEventSuccessIconWrapper>
              <GenericEventCancelIconWrapper
                onClick={() => {
                  setValues({
                    ...(values as RegistrationType),
                    eventCode: "",
                    eventName: "",
                    eventTypes: [],
                    eventStart: "",
                  });
                  if (journeyHelper.isSportsFormPromoterJourney(journey)) {
                    setEventRegistered(undefined);
                  }
                }}
              >
                <IconFa
                  faIcon={faClose}
                  color="gray"
                  aria-label="CloseIcon"
                  size="25px"
                />
              </GenericEventCancelIconWrapper>
            </IconBoxWrapper>
          ) : (
            <></>
          )}
        </Collapse>
      )}
    </EventLookupWrapper>
  );
};

export default EventLookup;
