import { GlobalStyle, Header, Loader } from "@cruk/cruk-react-components";
import { FC, lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import ActivityRoutes from "./components/ActivityRoutes";
import { OptimizelyLoader } from "./components/OptimizelyLoader";
import StepTracker from "./components/StepTracker";
import ActivityContainer from "./containers/ActivityContainer";
import RegistrationContainer from "./containers/RegistrationContainer";
import ActivityContext from "./contexts/ActivityContext";
import { OptimizelyContextProvider } from "./contexts/OptimizelyContext";
import FormPage from "./Form/pages/FormPage";
import useDataLayer from "./hooks/useDataLayer";
import ThemeProvider, { themes } from "./provider/ThemeProvider";
import { setUpDataDog, trackDataDogError } from "./utils/dataDog";
import { Helmet } from "react-helmet";

const Admin = lazy(() => import("./Admin/pages/admin-page/AdminPage"));
const ErrorPage = lazy(() => import("./Form/pages/ErrorPage"));
const OPTIMIZELY_SDK_KEY =
  process.env.REACT_APP_OPTIMIZELY_SDK_KEY || "7TBv8dRDF9DWGwC12ZYnr";

const App: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDynamicConfigError, setDynamicConfigError] = useState(false);
  const { pushInteraction } = useDataLayer();
  const [baseUrl, setBaseUrl] = useState<string>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    process.env.REACT_APP_BASE_URL!
  );

  // Load Dynamic Configuration to configure authentication and backend integration.
  useEffect(() => {
    pushInteraction("eventId", uuidv4());

    if (!process.env.REACT_APP_CONFIG_FILE) {
      setIsLoading(false);
      setDynamicConfigError(true);
      return;
    }
    fetch(process.env.REACT_APP_CONFIG_FILE)
      .then((response) => {
        if (!response.ok) {
          trackDataDogError("Unable to receive dynamic config file");
          throw new Error("Unable to receive dynamic config file");
        }
        return response.json();
      })
      .then(
        (config: {
          userPoolId: string;
          userPoolClientId: string;
          userPoolDomain: string;
          apiGraphQlEndpoint: string;
          publicApiKey: string;
          url: string;
        }) => {
          Amplify.configure({
            aws_project_region: process.env.REACT_APP_REGION,
            aws_cognito_region: process.env.REACT_APP_REGION,
            aws_user_pools_id: config.userPoolId,
            aws_user_pools_web_client_id: config.userPoolClientId,
            oauth: {
              domain: config.userPoolDomain,
              redirectSignIn: `${config.url}/admin`,
              redirectSignOut: config.url,
              responseType: "code",
            },
            federationTarget: "COGNITO_USER_POOLS",
            API: {
              aws_appsync_graphqlEndpoint: config.apiGraphQlEndpoint,
              aws_appsync_region: process.env.REACT_APP_REGION,
              aws_appsync_authenticationType: "API_KEY",
              aws_appsync_apiKey: config.publicApiKey,
            },
          });
          setBaseUrl(config.url);
          setUpDataDog(config.apiGraphQlEndpoint);
          setIsLoading(false);
          return true;
        }
      )
      .catch((err) => {
        setDynamicConfigError(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        trackDataDogError(err);
      });
  }, []);

  const RenderPage = ({
    element,
    theme,
    header,
  }: {
    element: JSX.Element;
    theme?: "cruk" | "rfl" | "su2c" | "dry";
    header?: boolean;
  }) => (
    <ThemeProvider themeKey={theme || "cruk"}>
      <GlobalStyle />
      {header && <Header />}
      <Suspense fallback={null}>{element}</Suspense>
    </ThemeProvider>
  );

  return isDynamicConfigError ? (
    <RenderPage element={<ErrorPage errorType="error" />} />
  ) : isLoading ? (
    <Loader />
  ) : (
    <OptimizelyContextProvider>
      <OptimizelyLoader
        sdkKey={OPTIMIZELY_SDK_KEY}
        onError={(err: Error) => {
          console.log(err, {
            component: "OptimizelyLoader",
            function: "setOptimizelyContext",
          });
        }}
      />
      <Routes
        children={
          <>
            <Route
              path="/error"
              element={<RenderPage element={<ErrorPage errorType="error" />} />}
            />

            <Route path="*" element={<Navigate to="/error" />} />

            {process.env.REACT_APP_ENABLE_ADMIN === "yes" && (
              <Route
                path="/admin/*"
                element={
                  <RenderPage
                    header={false}
                    element={<Admin baseUrl={baseUrl} />}
                  />
                }
              />
            )}

            <Route
              path="/:urlName/*"
              element={
                <Routes>
                  <Route
                    path="/error/closed"
                    element={
                      <ActivityContainer isErrorPage>
                        <ActivityContext.Consumer>
                          {(activity) => (
                            <RenderPage
                              theme={activity.theme}
                              element={<ErrorPage errorType="closed" />}
                            />
                          )}
                        </ActivityContext.Consumer>
                      </ActivityContainer>
                    }
                  />

                  <Route
                    path="/error/registration"
                    element={
                      <ActivityContainer isErrorPage>
                        <ActivityContext.Consumer>
                          {(activity) => (
                            <RenderPage
                              theme={activity.theme}
                              element={<ErrorPage errorType="registration" />}
                            />
                          )}
                        </ActivityContext.Consumer>
                      </ActivityContainer>
                    }
                  />

                  <Route
                    path="/*"
                    element={
                      <ActivityContainer isErrorPage={false}>
                        <ActivityContext.Consumer>
                          {(activity) => (
                            <>
                              <Helmet>
                                {activity?.metaTitle ? (
                                  <title>{activity.metaTitle}</title>
                                ) : (
                                  <title>
                                    {themes[activity.theme].siteConfig.title}
                                  </title>
                                )}
                                {activity?.metaDescription ? (
                                  <meta
                                    name="description"
                                    content={activity.metaDescription}
                                  />
                                ) : (
                                  <meta
                                    name="description"
                                    content="Cancer Research UK"
                                  />
                                )}
                              </Helmet>
                              <ThemeProvider themeKey={activity.theme}>
                                <GlobalStyle />
                                <Header>
                                  <Suspense fallback={null}>
                                    <ActivityRoutes>
                                      {({ page, currentStep, totalSteps }) =>
                                        page === "thank-you" ? null : (
                                          <StepTracker
                                            currentStep={currentStep}
                                            totalSteps={totalSteps}
                                          />
                                        )
                                      }
                                    </ActivityRoutes>
                                  </Suspense>
                                </Header>
                                <RegistrationContainer>
                                  <Suspense fallback={null}>
                                    <ActivityRoutes>
                                      {({ journey, page }) => (
                                        <FormPage
                                          journey={journey}
                                          currentPage={page}
                                        />
                                      )}
                                    </ActivityRoutes>
                                  </Suspense>
                                </RegistrationContainer>
                              </ThemeProvider>
                            </>
                          )}
                        </ActivityContext.Consumer>
                      </ActivityContainer>
                    }
                  />
                </Routes>
              }
            />
          </>
        }
      />
    </OptimizelyContextProvider>
  );
};

export default App;
