// eslint-disable-next-line eslint-comments/disable-enable-pair

/* eslint-disable no-underscore-dangle */
import { GraphQLResult, API } from "@aws-amplify/api";
import {
  ActivitiesDocument,
  ActivitiesQuery,
  Activity,
  ActivityDocument,
  ActivityInput,
  ActivityNotFoundError,
  ActivityQuery,
  ActivityRegistrationInput,
  ActivityRegistrationSuccess,
  ActivityTypesDocument,
  ActivityTypesQuery,
  EventDocument,
  EventListResultSuccess,
  EventQuery,
  EventResultSuccess,
  EventsDocument,
  EventsInput,
  EventsQuery,
  Exact,
  GetActivitiesInput,
  GetActivityInput,
  GetProductInput,
  GetProductsInput,
  Material,
  MutateActivityDocument,
  MutateActivityMutation,
  MutateProductDocument,
  MutateProductMutation,
  Product,
  ProductCodesDocument,
  ProductCodesQuery,
  ProductDocument,
  ProductInput,
  ProductNotFoundError,
  ProductQuery,
  ProductsDocument,
  ProductsQuery,
  ServerError,
  SubmitActivityRegistrationDocument,
  SubmitActivityRegistrationMutation,
  SupporterMaterialsDocument,
  SupporterMaterialsQuery,
  ValidationError,
} from "@cruk/activity-management-schema";
import {
  convertActivityResponseToActivity,
  convertEventResponseToEvent,
  convertEventsResponseToEvents,
  convertProductResponseToProduct,
} from "@cruk/activity-management-schema/dist/utils";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { ListActivity } from "../../Admin/types";

async function query<
  TData,
  TVariables extends Record<string, unknown> = Record<string, never>,
>(
  operation: TypedDocumentNode<
    TData,
    Exact<{
      [key: string]: never;
    }> & { input: TVariables }
  >,
  requiresAuth: boolean,
  variables?: TVariables
): Promise<TData> {
  const response = (await API.graphql({
    //@ts-ignore
    query: operation,
    variables: variables ? { input: variables } : undefined,
    authMode:
      process.env.REACT_APP_DISABLE_AUTH === "true" || !requiresAuth
        ? "API_KEY"
        : "AMAZON_COGNITO_USER_POOLS",
  })) as GraphQLResult<TData>;

  if (response.errors) {
    throw new Error(
      `Error occurred when calling GraphQL API ${response.errors
        .map((e) => `${e.name}:${e.message}`)
        .join(",")}`
    );
  }

  if (!response.data) {
    throw new Error("No data returned when calling GraphQL API");
  }

  return response.data;
}

export async function getActivityTypes(): Promise<string[]> {
  const response = await query<ActivityTypesQuery>(ActivityTypesDocument, true);
  if (!response.activityTypes) {
    throw new Error("No response found");
  }
  switch (response.activityTypes.__typename) {
    case "ActivityTypes":
      return response.activityTypes.types.filter((at) => at);
    case "ServerError":
      throw new Error(`Server error: ${response.activityTypes.message}`);
    default:
      throw new Error("Unable to determine type");
  }
}

export async function getProductCodes(): Promise<string[]> {
  const response = await query<ProductCodesQuery>(ProductCodesDocument, true);
  if (!response.productCodes) {
    throw new Error("Product Codes: No response found");
  }
  switch (response.productCodes.__typename) {
    case "ProductCodes":
      return response.productCodes.codes.filter((code) => code);
    case "ServerError":
      throw new Error(`Server error: ${response.productCodes.message}`);
    default:
      throw new Error("Unable to determine type");
  }
}

export async function getProducts({
  enabled,
}: {
  enabled?: boolean;
}): Promise<Product[]> {
  const response = await query<ProductsQuery, GetProductsInput>(
    ProductsDocument,
    true,
    { enabled }
  );
  if (!response.products) {
    throw new Error("No response found");
  }
  switch (response.products.__typename) {
    case "Products":
      return response.products.products;
    case "ServerError":
      throw new Error(`Server error: ${response.products.message}`);
    default:
      throw new Error("Unable to determine type");
  }
}

export async function mutateProduct(
  input: ProductInput
): Promise<Product | ValidationError | ProductNotFoundError | ServerError> {
  const response = await query<MutateProductMutation, ProductInput>(
    MutateProductDocument,
    true,
    input
  );
  if (!response.product) {
    throw new Error("No response found");
  }
  switch (response.product.__typename) {
    case "Product": {
      return convertProductResponseToProduct(response);
    }
    case "ProductNotFoundError":
      throw new Error(`Server error: ${response.product.message}`);
    case "ValidationError":
      throw new Error(`Server error: ${response.product.message}`);
    case "ServerError":
      throw new Error(`Server error: ${response.product.message}`);
    default:
      throw new Error("Unable to determine type");
  }
}

export async function getProductById(
  id: string
): Promise<Product | ValidationError | ProductNotFoundError | ServerError> {
  const response = await query<ProductQuery, GetProductInput>(
    ProductDocument,
    false,
    { id }
  );
  if (!response.product) {
    throw new Error("No response found");
  }
  switch (response.product.__typename) {
    case "Product": {
      return convertProductResponseToProduct(response);
    }
    case "ProductNotFoundError":
      throw new Error(`Server error: ${response.product.message}`);
    case "ValidationError":
      throw new Error(`Server error: ${response.product.message}`);
    case "ServerError":
      return response.product;
    default:
      throw new Error("Unable to determine type");
  }
}

export async function getSupporterMaterials(): Promise<Material[]> {
  const response = await query<SupporterMaterialsQuery>(
    SupporterMaterialsDocument,
    false,
    {}
  );
  if (!response.supporterMaterials) {
    throw new Error("No response found");
  }
  switch (response.supporterMaterials.__typename) {
    case "SupporterMaterials":
      return response.supporterMaterials.materials as Material[];
    case "ServerError":
      throw new Error(`Server error: ${response.supporterMaterials.message}`);
    default:
      throw new Error("Unable to determine type");
  }
}

export async function mutateActivity(
  input: ActivityInput
): Promise<Activity | ValidationError | ActivityNotFoundError | ServerError> {
  const response = await query<MutateActivityMutation, ActivityInput>(
    MutateActivityDocument,
    true,
    input
  );
  if (!response.activity) {
    throw new Error("No response found");
  }
  switch (response.activity.__typename) {
    case "Activity":
      return convertActivityResponseToActivity(response);
    case "ActivityNotFoundError":
    case "ValidationError":
    case "ServerError":
      return response.activity;
    default:
      throw new Error("Unable to determine type");
  }
}

export async function getActivityById(
  id: string
): Promise<Activity | ValidationError | ActivityNotFoundError | ServerError> {
  const response = await query<ActivityQuery, GetActivityInput>(
    ActivityDocument,
    false,
    { id }
  );
  if (!response.activity) {
    throw new Error("No response found");
  }
  switch (response.activity.__typename) {
    case "Activity": {
      return convertActivityResponseToActivity(response);
    }
    case "ActivityNotFoundError":
    case "ValidationError":
    case "ServerError":
      return response.activity;
    default:
      throw new Error("Unable to determine type");
  }
}

export async function getActivityByUrl(
  url: string
): Promise<Activity | ValidationError | ActivityNotFoundError | ServerError> {
  const response = await query<ActivityQuery, GetActivityInput>(
    ActivityDocument,
    false,
    { url }
  );
  if (!response.activity) {
    throw new Error("No response found");
  }
  switch (response.activity.__typename) {
    case "Activity": {
      return convertActivityResponseToActivity(response);
    }
    case "ActivityNotFoundError":
    case "ValidationError":
    case "ServerError":
      return response.activity;
    default:
      throw new Error("Unable to determine type");
  }
}

export async function getEvents(
  searchString: string
): Promise<EventListResultSuccess> {
  const response = await query<EventsQuery, EventsInput>(
    EventsDocument,
    false,
    { searchInput: searchString }
  );

  return convertEventsResponseToEvents(response);
}

export async function getEvent(
  searchString: string
): Promise<EventResultSuccess> {
  const response = await query<EventQuery, EventsInput>(EventDocument, false, {
    searchInput: searchString,
  });

  return convertEventResponseToEvent(response);
}

export async function submitRegistration(
  input: ActivityRegistrationInput
): Promise<ActivityRegistrationSuccess> {
  const response = await query<
    SubmitActivityRegistrationMutation,
    ActivityRegistrationInput
  >(SubmitActivityRegistrationDocument, false, input);
  if (!response.submitActivityRegistration) {
    throw new Error("No response found");
  }
  switch (response.submitActivityRegistration.__typename) {
    case "ActivityRegistrationSuccess": {
      const success = response.submitActivityRegistration;
      return {
        __typename: "ActivityRegistrationSuccess",
        id: success.id,
        fundraisingPageRedirectUrl:
          success.fundraisingPageRedirectUrl ?? undefined,
      };
    }
    case "ServerError":
      throw new Error(
        `Server error: ${response.submitActivityRegistration.message}`
      );
    case "ValidationError":
      throw new Error(
        `Validation error: ${response.submitActivityRegistration.message}`
      );
    default:
      throw new Error("Unable to determine type");
  }
}

export async function getActivities(): Promise<ListActivity[]> {
  let nextToken;
  const activities = [];
  do {
    // eslint-disable-next-line no-await-in-loop
    const page: ActivitiesPage = await getActivitiesPage(nextToken);
    nextToken = page.nextToken;
    activities.push(...page.activities);
  } while (nextToken);
  return activities;
}

type ActivitiesPage = { activities: ListActivity[]; nextToken?: string };

async function getActivitiesPage(nextToken?: string): Promise<ActivitiesPage> {
  const response = await query<ActivitiesQuery, GetActivitiesInput>(
    ActivitiesDocument,
    true,
    {
      nextToken,
    }
  );
  if (!response.activities) {
    throw new Error("No response found");
  }
  switch (response.activities.__typename) {
    case "Activities":
      return {
        activities: response.activities.activities.filter(
          (a) => a
        ) as ListActivity[],
        nextToken: response.activities.nextToken ?? undefined,
      };
    case "ServerError":
      throw new Error(`Server error: ${response.activities.message}`);
    case "ValidationError":
      throw new Error(`Server error: ${response.activities.message}`);
    default:
      throw new Error("Unable to determine type");
  }
}
